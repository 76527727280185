export enum AssessmentSubmitBtnType {
  QUIZ = "تسليم الإمتحان",
  HOMEWORK = "تسليم الواجب",
}

export enum AssessmentResultTextType {
  QUIZ = "درجتك في الكويز",
  HOMEWORK = "درجتك في الواجب",
}

export enum AssessmentMode {
  QUIZ = "quiz",
  HOMEWORK = "hw",
}

export enum VideoProvider {
  ELITE = "elite",
  PLYR = "plyr",
  BUNNY = "bunny",
  VIMEO = "vimeo",
}

export interface HomeworkData {
  score?: number;
  maxScore?: number;
  percentage?: number;
  isEnded: boolean;
  isStarted: boolean;
  questions: QuestionData[];
}

export type QuestionData = {
  question_id: number;
  question_type: string;
  title: string;
  content: string;
  img: string;
  weight: number;
  answers: string[];
  correct_answer: string;
  hint: string;
  userAnswer?: string;
  isCorrect?: boolean;
};

export type PastQuizTrial = {
  quiz_id: string[];
  quiz: string[];
  student: string[];
  student_id: string[];
  grade: string[];
  student_name: string[];
  date_submitted: string[];
  answers: string[];
  raw_data: string[];
  lesson_id: string[];
};

export type VideoData = {
  video_id: string;
  video_id2?: string;
  video_title: string;
  provider?: VideoProvider;
};

export interface LessonDataI {
  is_purchased: boolean;
  lesson_id: number;
  title: string;
  date: string;
  price: number;
  video_exist?: boolean;
  videos_data?: string;
  video_host?: string;
  quiz_id?: number;
  quiz_title?: string;
  hide_correct_answers?: boolean;
  must_answer_all?: boolean;
  quiz_duration?: number;
  quiz_questions?: QuestionData[];
  quiz_randomize?: boolean;
  quiz_trials?: number;
  quiz_required?: boolean;
  hw_questions?: QuestionData[];
  hw_id?: number;
  hw_result: string;
  lesson_files?: string[];
  views_notification_mark?: number;
  last_trial_date?: string;
  expiry_date?: number;
  remaining_views?: number;
  code?: boolean;
  wallet?: boolean;
  fawry?: boolean;
  vodafone_cash?: boolean;
  last_purchase_date?: string;
  pre?: number;
  hw_raw_data?: string;
  newly_purchased?: boolean;
  is_author?: boolean;
  past_quiz_trials?: PastQuizTrial[];
  xvast_protection?: boolean;
  is_offline_purchase?: boolean;
}
