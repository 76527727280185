import VideosMap from "../../../video/VideosMap";
import { LessonDataI } from "../../../quiz/typesAndEnums";
import useVideoHandler from "../hooks/useVideoHandler";

const VideoContent = ({ lessonData }: { lessonData: LessonDataI }) => {
  const {
    videos,
    isExpired,
    videoExist,
    files,
    expirtDate,
    isProtected,
    getVideoID,
    handleVideoView,
  } = useVideoHandler({ lessonData });

  if (!videoExist) {
    return (
      <div className="p-5 d-flex">
        <h2 className="m-auto">لا يوجد فيديو</h2>
      </div>
    );
  }

  if (isExpired) {
    return (
      <div className="alert alert-danger m-5 rounded-ea" role="alert">
        إنتهت صلاحية الحصة
      </div>
    );
  }

  return (
    <VideosMap
      videos={videos}
      lessonData={lessonData}
      expirtDate={expirtDate}
      files={files}
      isProtected={isProtected}
      getVideoID={getVideoID}
      handleVideoView={handleVideoView}
    />
  );
};

export default VideoContent;
