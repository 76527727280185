import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { useAppSelector } from "../../../../store/hooks";
import { fetchLesson } from "../api/fetchLesson";
import { checkUnansweredQuestions } from "../utils/checkUnansweredQuestions";
import useCheckIsMobile from "../utils/useCheckIsMobile";

export enum LessonTabs {
  QUIZ = "quiz",
  VIDEO = "video",
  HOMEWORK = "hw",
}

const useGetLesson = () => {
  const { lesson } = useParams();
  const [currentContent, setCurrentContent] = useState<LessonTabs>(
    LessonTabs.QUIZ
  ); // 'quiz' || 'video' || 'hw'
  const { user, userProfileData } = useAppSelector((state) => state.store);
  const isAuthenticated = user;
  const authPending = !user;

  const {
    data: lessonData,
    isPending,
    error,
  } = useQuery({
    queryKey: ["lesson", lesson, userProfileData],
    queryFn: () => fetchLesson(lesson),
    staleTime: 0,
  });

  const currentTimestamp = Date.now();
  const expiryTimestamp = Date.parse(lessonData?.last_purchase_date ?? "");

  const pastLastPurchaseDate =
    lessonData?.last_purchase_date && currentTimestamp > expiryTimestamp;
  const notFinishedPrereq =
    (!lessonData?.hw_raw_data && !!lessonData?.pre) ||
    checkUnansweredQuestions(JSON.parse(lessonData?.hw_raw_data ?? "{}"));

  const isStudentBlocked =
    userProfileData &&
    userProfileData.disabled_sites &&
    userProfileData.disabled_sites.filter(
      (site) => site.toString() === window.location.hostname.toString()
    ).length > 0;

  const { isMobile } = useCheckIsMobile();

  return {
    lessonData,
    isPending,
    error,
    currentContent,
    setCurrentContent,
    isAuthenticated,
    authPending,
    user,
    userProfileData,
    pastLastPurchaseDate,
    notFinishedPrereq,
    isStudentBlocked,
    isMobile,
  };
};

export default useGetLesson;
