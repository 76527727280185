import AssessmentResultMsg from "./components/AssessmentResultMsg";
import AssessmentQuestions from "./components/AssessmentQuestions";
import AssessmentSubmitBtn from "./components/AssessmentSubmitBtn";
import {
  AssessmentMode,
  AssessmentResultTextType,
  AssessmentSubmitBtnType,
  LessonDataI,
} from "./typesAndEnums";
import useHomeworkHandler from "./hooks/useHomeworkHandler";

const Homework = ({ lessonData }: { lessonData: LessonDataI }) => {
  const {
    score,
    maxScore,
    percentage,
    isEnded,
    isStarted,
    questions,
    isSubmitPending,
    submitError,
    currentTeacher,
    handleSubmitHomework,
  } = useHomeworkHandler({ lessonData });

  if (!lessonData.quiz_id)
    return (
      <div className="p-5 d-flex">
        {currentTeacher === "mr-hazem-el-kashif" ||
        window.location.hostname.includes("kashif") ? (
          <h2 className="m-auto">الواجب في الWorkbook</h2>
        ) : (
          <h2 className="m-auto">لا يوجد واجب</h2>
        )}
      </div>
    );

  return (
    <div>
      {isEnded && (
        <AssessmentResultMsg
          title={AssessmentResultTextType.HOMEWORK}
          score={score}
          maxScore={maxScore}
          percentage={percentage}
        />
      )}

      {isStarted && !isEnded && (
        <div className="alert alert-info mt-2 mx-3 rounded-ea" role="alert">
          يتم حفظ الأجابات تلقائياً علي جهازك, يمكنك العودة لإكمال الواجب في أي
          وقت <strong>من نفس الجهاز</strong>
        </div>
      )}

      {(isStarted || isEnded) && (
        <form
          className="quiz-form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitHomework();
          }}
        >
          <AssessmentQuestions
            assessmentMode={AssessmentMode.HOMEWORK}
            assessmentId={lessonData?.hw_id}
            questions={questions}
            isEnded={isEnded}
            mustAnswerAll={lessonData.must_answer_all}
            hideCorrectAnswers={lessonData.hide_correct_answers}
          />

          <AssessmentSubmitBtn
            isStarted={isStarted}
            isEnded={isEnded}
            isSubmitPending={isSubmitPending}
            btnText={AssessmentSubmitBtnType.HOMEWORK}
          />

          {submitError && (
            <div className="alert alert-danger m-3 rounded-ea" role="alert">
              حدث خطأ أثناء تسليم الواجب , تواصل مع الدعم الفني
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default Homework;
